import * as Sentry from '@sentry/browser'
import * as moment from 'moment'
import 'moment/locale/de'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import Footer from './components/layout/footer'
import Header from './components/layout/header'
import OfflineNotification from './components/offline-notification'
import AppRouter from './router'
import * as serviceWorker from './serviceWorkerRegistration'
import createStore from './store/createStore'
import './styles/app.scss'
import AsyncStoreInit from './utils/async-store-init'
import Klaro from './utils/consent-management'
import UserSessionHandler from './utils/user-session-handler'
import WebsiteSchema from './utils/website-schema'

const FideoVersion = '1.6.0'

if (document.URL.indexOf('localhost') === -1) {
    Sentry.init({
        dsn: 'https://d4eb541c32ee482db4c9938308d0b302@o397807.ingest.sentry.io/5252688',
    })
}

const store = createStore()

const rootContainer = document.getElementById('root') as HTMLElement
const root = createRoot(rootContainer)
root.render(
    <Provider store={store}>
        <AsyncStoreInit />
        <UserSessionHandler />
        <WebsiteSchema />
        <Router>
            <Header />
            <main role="main">
                <AppRouter />
            </main>
            <Footer />
            <OfflineNotification />
        </Router>
        <Klaro />
    </Provider>
)

moment.locale('de')

serviceWorker.register({
    onUpdate: () => {
        document.body.insertAdjacentHTML(
            'afterbegin',
            `
            <aside class="component--offline-notification">
                <p>Für FIDEO steht ein Update bereit. Bitte <a href="javascript:window.refreshCacheAndReload();">lade die Seite neu</a>.</p>
            </aside>`
        )
    },
})

window.setTimeout(() => {
    const tag = document.createElement('meta')
    tag.setAttribute('name', 'generator')
    tag.content = `FIDEO v${FideoVersion}`
    document.head.appendChild(tag)
}, 2500)

/**
 * Invalidating the cache of a PWA can be hard as both, browser- &
 * serviceWorker-cache are concerned.
 * Forcefully flush both and reload the application!
 *
 * @see https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
 */
;(window as any).refreshCacheAndReload = () => {
    if (window.caches) {
        // Service worker cache should be cleared with caches.delete()
        window.caches.keys().then(function (names) {
            for (let name of names) caches.delete(name)
        })
    }

    // Delete browser cache and hard reload
    // The "true" flag is deprecated, but quite relevant
    // @see https://github.com/Microsoft/TypeScript/issues/28898
    ;(window.location as any).reload(true)
}
